import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../configs/config';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },

        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify/:phoneNumber', element: <VerifyCode /> },
        { path: 'new-password/:phoneNumber', element: <NewPassword /> },
        { path: 'signup', element: <Register /> },
        // { path: 'signup/otp-verify', element: <OtpVerify /> },
        { path: 'signup/confirm', element: <UpdateUserPage /> },
        { path: 'verifyEmail/:token', element: <VerifyEmailForSignUpPage /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <GeneralDashboard /> },
        { path: 'vendor', element: <Vendors /> },

        { path: 'orders', element: <Orders /> },
        { path: 'leads', element: <Leads /> },
        { path: 'profile', element: <Profile /> },
        // { path: 'changepassword', element: <ChangePassword /> },

        {
          path: 'leads',
          children: [
            // { element: <Navigate to="/dashboard/leads" replace />, index: true },
            { path: 'pending', element: <Leads /> },
            { path: 'new', element: <Leads /> },
            { path: 'rejected', element: <Leads /> },
            { path: 'list', element: <Leads /> },
            { path: 'import', element: <LeadImportForm /> },
            { path: 'add', element: <AddNewLeadForm /> },
            { path: ':id/edit', element: <EditLeads /> },

            // { path: 'leadDetail', element: <ViewLeadDetailForm /> },
            // { path: ':id/inreviewleadsdetail', element: <InreviewLeadsDetail /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { path: 'pending', element: <Orders /> },
            { path: 'confirm', element: <Orders /> },
            { path: 'reject', element: <Orders /> },
            { path: 'cancel', element: <Orders /> },
            { path: 'all', element: <Orders /> },
            { path: 'ViewOrderDetailsTable/:id', element: <ViewOrderDetailsTable /> },
          ],
        },
        {
          children: [{ path: 'farmers', element: <FarmerList /> }],
        },
        {
          path: 'vendors',
          children: [
            { path: 'customers', element: <Vendors /> },
            { path: 'dealers', element: <DealerList /> },
          ],
        },
        {
          path: 'vendor',
          children: [
            { path: ':id/edit', element: <EditVendor /> },
            { path: 'add', element: <AddVendor /> },
            { path: 'import', element: <VendorImport /> },
          ],
        },
        {
          path: 'designation',
          children: [
            { path: 'list', element: <Designation /> },
            { path: 'hierarchy', element: <DesignationHirarchy /> },
            { path: 'designation-relation', element: <DesignationRelation /> },
          ],
        },

        {
          path: 'employee',
          children: [
            { path: 'active', element: <EmployeeList /> },
            { path: 'inactive', element: <EmployeeList /> },
            { path: 'deleted', element: <EmployeeList /> },
            {
              path: 'mda',
              children: [
                { path: 'active', element: <MDAEmployeeList /> },
                { path: 'inactive', element: <MDAEmployeeList /> },
                { path: 'deleted', element: <MDAEmployeeList /> },
              ],
            },
            { path: 'employeetarget', element: <EmployeeTarget /> },
            { path: 'attendance', element: <EmployeeAttendance /> },
            { path: 'registered', element: <RegisteredEmployee /> },
            { path: 'rejected', element: <RejectedEmployee /> },
          ],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/dashboard/inventory/category" replace />, index: true },
            { path: 'category', element: <ProductCategory /> },
            { path: 'products', element: <ProductList /> },
            { path: 'stock', element: <StockManagement /> },
            { path: 'stock/:id', element: <StockManagement /> },
            { path: 'price', element: <PriceList /> },
            {
              path: 'category',
              children: [
                { path: 'add', element: <AddCategory /> },
                { path: ':id/edit', element: <EditProductCategory /> },
              ],
            },
            {
              path: 'products',
              children: [
                // { path: ':id/edit', element: <EditProduct /> },
                { path: 'import', element: <ProductImport /> },
                // { path: 'add', element: <Addproduct /> },
              ],
            },
            {
              path: 'stock',
              children: [
                // { path: 'add', element: <AddStock /> },
                { path: 'import', element: <StockImport /> },
                // { path: ':id/edit', element: <AddStock /> },
              ],
            },
          ],
        },
        {
          children: [
            {
              path: 'meeting',
              element: <MeetingTable />,
            },
          ],
        },
        {
          path: 'profile',
          children: [
            { path: 'personal-information', element: <Profile /> },
            { path: 'company-detail', element: <Profile /> },
            { path: 'bank-detail', element: <Profile /> },
            { path: 'change-password', element: <Profile /> },
          ],
        },

        {
          path: 'unit',
          children: [{ path: 'Hierarchy', element: <UnitHierarchy /> }],
        },
        {
          children: [
            {
              path: 'regionList',
              element: <RegionMaster />,
            },
          ],
        },
        {
          path: 'settings',
          children: [
            { path: 'notification', element: <NotificationSetting /> },
            { path: 'territoryUpload', element: <TerritoryUpload /> },
          ],
        },
        {
          children: [
            {
              path: 'UnauthorizedPage',
              element: <UnauthorizedPage />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/newPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const OtpVerify = Loadable(lazy(() => import('../pages/auth/OtpVerify')));
const UpdateUserPage = Loadable(lazy(() => import('../components/PleaseCheckEmailComponent')));
const VerifyEmailForSignUpPage = Loadable(lazy(() => import('../components/VerifyEmailForSignupPage')));
// Dashboard
const GeneralDashboard = Loadable(lazy(() => import('../pages/dashboard/GeneralDashboard')));

const ProductCategory = Loadable(lazy(() => import('../sections/@dashboard/tables/ProductCategoryTable')));
const ProductList = Loadable(lazy(() => import('../sections/@dashboard/tables/ProductListTable')));
const StockManagement = Loadable(lazy(() => import('../pages/dashboard/StockManagement')));
const PriceList = Loadable(lazy(() => import('../sections/@dashboard/tables/PriceListTable')));
const Vendors = Loadable(lazy(() => import('../sections/@dashboard/tables/VendorsList')));

const Leads = Loadable(lazy(() => import('../pages/dashboard/Leads')));
const LeadImportForm = Loadable(lazy(() => import('../sections/@dashboard/import/LeadImport')));
// const ViewLeadDetailForm = Loadable(lazy(() => import('../sections/@dashboard/forms/ViewLeadDetailForm')));
const Orders = Loadable(lazy(() => import('../pages/dashboard/Orders')));
const ViewOrderDetailsTable = Loadable(
  lazy(() => import('../sections/@dashboard/detail/orderdetail/ViewOrderDetailsTable'))
);
const Designation = Loadable(lazy(() => import('../sections/@dashboard/tables/DesignationListTable')));
const DesignationHirarchy = Loadable(lazy(() => import('../sections/@dashboard/tables/DeignationHierarchyTable')));
const DesignationRelation = Loadable(lazy(() => import('../sections/@dashboard/tables/DesignationRelation')));
const EmployeeTarget = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeTargetTable')));
const EmployeeList = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeList')));
const MDAEmployeeList = Loadable(lazy(() => import('../sections/@dashboard/tables/MDAEmployeeList')));
const EmployeeAttendance = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeAttendanceReportTable')));
const RegisteredEmployee = Loadable(lazy(() => import('../sections/@dashboard/tables/RegisteredEmployeeListTable')));
const RejectedEmployee = Loadable(lazy(() => import('../sections/@dashboard/tables/RejectedEmployeeListTable')));
const FarmerList = Loadable(lazy(() => import('../sections/@dashboard/tables/FarmerList')));
const DealerList = Loadable(lazy(() => import('../sections/@dashboard/tables/DealerList')));

const RegionMaster = Loadable(lazy(() => import('../sections/@dashboard/tables/RegionListTable')));
const MeetingTable = Loadable(lazy(() => import('../sections/@dashboard/tables/MeetingTable')));
const UnitHierarchy = Loadable(lazy(() => import('../sections/@dashboard/tables/UnitHierarchyTable')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Profile')));
// const ChangeProfilePassword = Loadable(lazy(() => import('../pages/dashboard/ChangePassword')));
// form section
// const ProfileForm = Loadable(lazy(() => import('../sections/@dashboard/forms/profile/ProfileForm')));
const AddNewLeadForm = Loadable(lazy(() => import('../sections/@dashboard/forms/LeadForm')));
const EditLeads = Loadable(lazy(() => import('../sections/@dashboard/forms/LeadForm')));
const StockImport = Loadable(lazy(() => import('../sections/@dashboard/import/StockImport')));
const ProductImport = Loadable(lazy(() => import('../sections/@dashboard/import/ProductImport')));
// const EditProduct = Loadable(lazy(() => import('../sections/@dashboard/forms/ProductForm')));
// const AddStock = Loadable(lazy(() => import('../sections/@dashboard/forms/StockForm')));
const AddCategory = Loadable(lazy(() => import('../sections/@dashboard/forms/inventory/CategoryForm')));
const EditProductCategory = Loadable(lazy(() => import('../sections/@dashboard/forms/inventory/CategoryForm')));
// const Addproduct = Loadable(lazy(() => import('../sections/@dashboard/forms/ProductForm')));
// const InreviewLeadsDetail = Loadable(lazy(() => import('../sections/@dashboard/forms/InreviewLeadsDetail')));
const EditVendor = Loadable(lazy(() => import('../sections/@dashboard/forms/VendorForm')));
const AddVendor = Loadable(lazy(() => import('../sections/@dashboard/forms/VendorForm')));
// const EditEmployeeTarget = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeTargetForm')));
// const AddEmployeeTarget = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeTargetForm')));
// const EditEmployee = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeListForm')));
// const AddEmployee = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeListForm')));
const VendorImport = Loadable(lazy(() => import('../sections/@dashboard/import/VendorImport')));
// const EmployeeImport = Loadable(lazy(() => import('../sections/@dashboard/import/EmployeeImport')));
const NotificationSetting = Loadable(lazy(() => import('../sections/@dashboard/tables/NotificationSetting')));
const UnauthorizedPage = Loadable(lazy(() => import('../pages/UnauthorizedPage')));
const TerritoryUpload = Loadable(lazy(() => import('../pages/dashboard/TerritoryUpload')));

// const ViewEmployeeActivity = Loadable(lazy(() => import('../sections/@dashboard/forms/ViewEmployeeActivity')));
