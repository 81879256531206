import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { startLoading, getCustomerDetails } from '../../slices/customerDetails';
import { hasError, startFormLoading, stopFormLoading, getErrorMessage } from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* CustomerDetailsSaga(action) {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());

    const { resourcetype } = action.payload;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/miscresoureentries`, {
      params: { resourcetype },
      headers: {
        'x-auth-token': Token,
      },
    });

    const customerData = response?.data?.data.data || [];
    yield put(getCustomerDetails(customerData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
