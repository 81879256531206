import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  customerDetails: [],
};
const customerDetails = createSlice({
  name: 'customerDetails',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getCustomerDetails(state, action) {
      state.isLoading = false;
      state.customerDetails = action.payload;
      state.error = false;
    },
  },
});

export default customerDetails.reducer;
export const {
  startLoading,
  hasError,
  getCustomerDetails,
} = customerDetails.actions;
