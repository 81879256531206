import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  getProduct,
  startProductLoading,
  getProductById,
  getAssignedEmployeesByProductId,
  getUnAssignedEmployeesByProductId,
  deleteProductList,
  changeProductStatus,
} from '../../slices/productListSlice';
import { GettingRoleID } from '../../../components/GettingRoleID';

// import {getProduct} from  '../../slices/productListSlice'
import {
  hasError,
  setResponse,
  startFormLoading,
  stopFormLoading,
  getErrorMessage,
  getDeleteMessage,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* inventoryProductListSaga(state) {
  try {
    yield put(hasError(false));
    yield put(setResponse(''));
    yield put(startFormLoading());
    yield put(startProductLoading(true));
    const { rowsPerPage, page, order, orderBy, filterName } = state;
    const Token = window.localStorage.getItem('auth-token');
    const sortParams = order && orderBy ? `&sort=${order}&column=${orderBy}` : '';
    const searchTextParams = filterName && filterName.trim() !== '' ? `&searchText=${filterName}` : '';

    const responses = yield axios.get(
      `${BASEURL}/products?limit=${rowsPerPage}&page=${page + 1}${sortParams}${searchTextParams}`,
      {
        headers: {
          'x-auth-token': `${Token}`,
        },
      }
    );
    const { products, totalRowsValue } = responses?.data?.data?.productList;
    if (products === undefined) {
      yield put(getProduct([]));
    } else {
      yield put(getProduct({ products, totalRowsValue }));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startProductLoading(false));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addNewProduct(satate) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = satate;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/products`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(inventoryProductListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* deleteProduct(state) {
  try {
    yield put(startProductLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/products/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteProductList(id));
    }
    // yield call(inventoryProductListSaga);
    yield put(startProductLoading(false));

    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startProductLoading(false));

    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getProductByIdSaga(state) {
  try {
    yield put(startFormLoading());

    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/products/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(stopFormLoading());
    yield put(getProductById(response.data.data[0]));
  } catch (error) {
    yield put(hasError(true));
  }
}

export function* updateProduct(satate) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item, index, statusChange } = satate;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/products/${index}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (statusChange !== 'statusChange') {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield call(inventoryProductListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* changeProductStatusSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startProductLoading());
    const { id, status } = state[0];
    const data = {
      status,
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/products/status/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(changeProductStatus({ data, id }));
    }
    // yield call(inventoryProductListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* productBulkUpload(state) {
  try {
    yield put(hasError(false));
    yield put(startProductLoading());

    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/products/uploadproduct`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignedEmployeeByProductIdSaga(state) {
  try {
    yield put(startFormLoading());
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    // PRODUCT id
    const response = yield axios.get(`${BASEURL}/products/assigned-employees/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getAssignedEmployeesByProductId(response.data.data.assignedEmployees));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
  }
}
export function* unAssignedEmployeeByProductIdSaga(state) {
  try {
    yield put(startFormLoading());
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const roleId = GettingRoleID('employee');

    // PRODUCT id
    const response = yield axios.get(`${BASEURL}/products/unassigned-employees/${id}?role_id=${roleId}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(getUnAssignedEmployeesByProductId(response.data.data.unassignedEmployees));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
  }
}
