import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import {
  getProductCategory,
  startLoading,
  pushNewCategory,
  deleteCategory,
  editCategory,
} from '../../slices/inventory';
import {
  hasError,
  setResponse,
  startFormLoading,
  getErrorMessage,
  getDeleteMessage,
  stopFormLoading,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* inventoryProductCategorySaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    yield put(startLoading(true));
    const { rowsPerPage, page, order, orderBy, filterName } = state;
    const Token = window.localStorage.getItem('auth-token');
    const sortParams = order && orderBy ? `&sort=${order}&column=${orderBy}` : '';
    const searchTextParams = filterName && filterName.trim() !== '' ? `&searchText=${filterName}` : '';

    const response = yield axios.get(
      `${BASEURL}/categories?limit=${rowsPerPage}&page=${page + 1}${sortParams}${searchTextParams}`,
      {
        headers: {
          'x-auth-token': `${Token}`,
        },
      }
    );
    const { category, totalRowsValue } = response?.data?.data?.categories;

    if (category === undefined) {
      yield put(getProductCategory([]));
    } else {
      yield put(getProductCategory({ category, totalRowsValue }));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* addNewCategorySaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());

    const { data } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/categories`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      let addCategoryData;
      if (response.data.data.categories.constructor === Array) {
        addCategoryData = response.data.data.categories;
      } else {
        addCategoryData = [response.data.data.categories];
      }
      const modifyName = addCategoryData.map((item) => ({
        ...item,
        status: 'Active',
        category_name: item.category,
      }));
      yield put(pushNewCategory(...modifyName));
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }

    // yield call(inventoryProductCategorySaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* deleteCategorySaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const id = state.idForDelete;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/categories/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteCategory(id));
    }
    // yield call(inventoryProductCategorySaga);
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(startLoading(false));

    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* editCategorySaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id } = state;
    const data = {
      category_name: state.editedData.category_name,
      status: state.editedData.status,
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/categories/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(editCategory({ data, id }));
      yield put(setResponse(response.status));
    }

    // yield call(inventoryProductCategorySaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* changeCategoryStatusSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startLoading());
    const { id } = state[0];
    const data = {
      category_name: state[0].category_name,
      status: state[0].status,
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/categories/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(editCategory({ data, id }));
    }

    // yield call(inventoryProductCategorySaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
