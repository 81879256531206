import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  productList: [],
  productListCount: undefined,
  response: '',
  productById: {},
  assignedEmployeesbyProductId: [],
  unAssignedEmployeesbyProductId: [],
};
const productListSlice = createSlice({
  name: 'productListSlice',
  initialState,
  reducers: {
    startProductLoading(state, action) {
      state.isLoading = action.payload;
    },
    getProduct(state, action) {
      state.isLoading = false;
      state.productList = action.payload.products;
      state.productListCount = action.payload.totalRowsValue;
    },
    deleteProductList(state, action) {
      state.productList = state.productList.filter((data) => data.id !== action.payload);
    },
    pushNewProduct(state, action) {
      state.isLoading = false;
      state.productList = [...state.productList, action.payload];
    },
    editProduct(state, action) {
      state.productList = action.payload;
    },
    pushProductsInBulk(state, action) {
      state.isLoading = false;
      state.productList = [...state.productList, ...action.payload];
    },
    getProductById(state, action) {
      state.isLoading = false;
      state.productById = action.payload;
    },
    getAssignedEmployeesByProductId(state, action) {
      state.isLoading = false;
      state.assignedEmployeesbyProductId = action.payload;
    },
    getUnAssignedEmployeesByProductId(state, action) {
      state.isLoading = false;
      state.unAssignedEmployeesbyProductId = action.payload;
    },
    changeProductStatus(state, action) {
      state.isLoading = false;
      state.productList = state.productList.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            status: action.payload.data.status,
            id: action.payload.id,
          };
        }
        return obj;
      });
    },
  },
});

export default productListSlice.reducer;
export const {
  startProductLoading,
  hasError,
  getProduct,
  pushNewProduct,
  editProduct,
  pushProductsInBulk,
  deleteProductList,
  pushNewStockInBulk,
  changeProductStatus,
  getProductById,
  getAssignedEmployeesByProductId,
  getUnAssignedEmployeesByProductId,
} = productListSlice.actions;
